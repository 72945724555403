body {
  margin: 0;
  padding: 0;
  font-family: Relative Trial Medium, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::-moz-selection { /* Code for Firefox */
    background: #F9E8E7;
  }
  
  ::selection {
    background: #F9E8E7;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'ABC Camera Unlicensed Trial Medium Italic';
  src: url(./assets/fonts/ABCCamera-MediumItalic-Trial.otf);
}

@font-face {
  font-family: 'ABC Camera Unlicensed Trial Bold';
  src: url(./assets/fonts/ABCCamera-Bold-Trial.otf);
}

@font-face {
  font-family: 'ABC Camera Plain Unlicensed Trial Regular';
  src: url(./assets/fonts/ABCCameraPlain-Regular-Trial.otf);
}

@font-face {
  font-family: 'ABC Camera Plain Unlicensed Trial Bold';
  src: url(./assets/fonts/ABCCameraPlain-Bold-Trial.otf);
}

@font-face {
  font-family: 'ABC Camera Plain Unlicensed Trial Medium';
  src: url(./assets/fonts/ABCCameraPlain-Medium-Trial.otf);
}

@font-face {
  font-family: 'Relative Trial Book';
  src: url(./assets/fonts/relative-book-trial.otf);
}

@font-face {
  font-family: 'Relative Trial Medium';
  src: url(./assets/fonts/relative-medium-trial.otf);
}

@font-face {
  font-family: 'Relative Trial Bold';
  src: url(./assets/fonts/relative-bold-trial.otf);
}

@font-face {
  font-family: 'Relative Trial Black';
  src: url(./assets/fonts/relative-black-trial.otf);
}